import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { getURL } from '~publish/legacy/utils/formatters'
import { createNew } from '~publish/legacy/routes'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import type { RouterActions } from 'redux-first-history'
import { useSplitEnabled } from '@buffer-mono/features'
import { useChannels } from '~publish/components/PublishSidebar/useChannels'
import { useAccount } from '~publish/legacy/accountContext'

type Action = () => void

export const useWelcomeCheckListActions = () => {
  const dispatch = useDispatch()
  const { channels } = useChannels()
  const { account } = useAccount()
  const connectedChannelIds = channels.map((channel) => channel.id)
  const { isEnabled: isChannelStorefrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )

  const { isEnabled: isVariantWelcomeChecklistEnabled } = useSplitEnabled(
    'geid-goals-in-welcome-checklist',
    { email: account?.email },
  )

  const onCreateIdeaClick = (): RouterActions => dispatch(createNew.goTo())

  const onSetPostingGoalClick = (args: {
    connectedChannelIds: string[]
  }): void => {
    const { connectedChannelIds } = args

    const { actions } = window.appshell
    // If there are no connected channels, open the channel storefront
    if (connectedChannelIds.length === 0) {
      actions.openChannelStorefront({
        cta: 'publish-welcomeChecklist-listItem-setPostingGoal-1',
        isSettingPostingGoal: true,
      })
    } else {
      // If we can get the channelID from the URL, use that
      // Otherwise, use the first connected channel
      const channelIdInURL =
        window.location.pathname.match(/^\/channels\/([^/]+)/)?.[1] ?? null
      const channelId = channelIdInURL ?? connectedChannelIds[0]
      actions.openModal('postingGoalConfiguration', {
        channelId,
      })
    }
  }

  const handleConnectChannel = (): void => {
    if (isChannelStorefrontEnabled) {
      const { actions } = window.appshell
      actions.openChannelStorefront({
        cta: 'publish-welcomeChecklist-listItem-connectYourFirstChannel-1',
      })
    } else {
      window.location.assign(getURL.getChannelConnectURL())
    }
  }

  const onPublishFirstPostClick = ({
    selectedProfileId,
    selectedProfileIds,
  }: {
    selectedProfileId: string | undefined
    selectedProfileIds?: string[]
  }): void => {
    dispatch(
      composerPopoverActions.handleCreatePostClick({
        selectedProfileId,
        selectedProfileIds,
        cta: SEGMENT_NAMES.CALENDAR_CREATE_A_POST,
      }),
    )
  }
  // NOTE: Currently hardcoded for showing checklist 1 only. We'll make this more dynamic as we add checklists
  const noOp = useCallback(() => undefined, [])
  const WELCOME_CHECKLIST_LINKS: Record<number, Action> = {
    1: () => noOp(),
    2: () => handleConnectChannel(),
    3: () =>
      isVariantWelcomeChecklistEnabled
        ? onSetPostingGoalClick({
            connectedChannelIds,
          })
        : onCreateIdeaClick(),
    4: () =>
      onPublishFirstPostClick({
        selectedProfileId: undefined,
        selectedProfileIds: [],
      }),
  }

  // Create a method to call actions
  const callWelcomeChecklistAction = (action: number): void => {
    WELCOME_CHECKLIST_LINKS[action]()
  }

  return { callWelcomeChecklistAction }
}
